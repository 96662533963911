import React from 'react'
import logo from './logo.png'
import './App.css'
import data from './data.json'
import Video from './VideoLoop.js'
import 'intersection-observer'
import handleViewport from 'react-in-viewport'
import youtubeButton from './youtube-button.svg'

// polyfills
import 'url-search-params-polyfill'
import 'whatwg-fetch'

function App() {
  let search = window.location.search
  let params = new URLSearchParams(search)
  let language = params.get('lang')
  let languageIsEnglish = language === 'de' ? false : true
  let showImprint = params.get('imprint')
  let text
  if (!data[0]) {
    return (<div className="TextBlock"><h2>loading</h2></div>)
  }
  if (languageIsEnglish) {
    text = data[0].en
  } else {
    text = data[0].de
  }
  const loop = data[0].video
  const VideoLoop = handleViewport(Video, /** options: {}, config: {} **/)
  console.log('imprint mode', showImprint)
  if (showImprint) {
    return (
      <div className="App">
        <a className="button" href={languageIsEnglish ? '/?lang=en' : '/?lang=de'}>
            <p>{text.backbutton || 'back'}</p>
        </a>
        <div className="TextBlock">
          <p dangerouslySetInnerHTML={{__html: text.imprint}}></p>
        </div>
      </div>
    )
  }
  return (
    <div className="App">
      <a className="langSwitcher" href={languageIsEnglish ? '/?lang=de' : '/?lang=en'}>
        <p>{languageIsEnglish ? 'DE' : 'EN'}</p>
      </a>

      <header className="App-header">
        <img src={logo} className="logo" alt="logo" draggable="false"/>
        <p className="subline">{text ? text.subline : 'no data'}</p>
      </header>

      <VideoLoop index="1" cover={loop['1'].cover} videoUrl={loop['1'].url}/>
      <div className="TextBlock">
        <p dangerouslySetInnerHTML={{__html: text.block1top}}></p>
        <h2>{text.block1headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block1small}}></p>
      </div>

      <a class='staticImageContainer' href="https://www.youtube.com/watch?v=YhjGLZMPVkY" target="_blank" rel="noopener noreferrer">
        <img src={youtubeButton} alt="youtube-play-button" className="youtubeButton"/>
        <img src="stills/themetropolitan.jpg" className="coverImage" alt="METROPOLITAN MUSEUM OF ART" draggable="false"/>
      </a>
      <div className="TextBlock">
        <h2>{text.block2headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block2small}}></p>
      </div>

      {/* <VideoLoop index="3" cover={loop['3'].cover} videoUrl={loop['3'].url} alt={loop['3'].alt}/>
      <div className="TextBlock">
        <h2>{text.block3headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block3small}}></p>
      </div> */}

      {/* <VideoLoop index="4" cover={loop['4'].cover} videoUrl={loop['4'].url} alt={loop['4'].alt}/> */}
      <div className="TextBlock">
        <h2>{text.block4headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block4small}}></p>
      </div>

      <VideoLoop index="5" cover={loop['5'].cover} videoUrl={loop['5'].url} alt={loop['5'].alt}/>
      <div className="TextBlock">
        <h2>{text.block5headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block5small}}></p>
      </div>

      <VideoLoop index="6" cover={loop['6'].cover} videoUrl={loop['6'].url} alt={loop['6'].alt}/>
      <div className="TextBlock">
        <h2>{text.block6headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block6small}}></p>
      </div>

      <VideoLoop index="7" cover={loop['7'].cover} videoUrl={loop['7'].url} alt={loop['7'].alt}/>
      <div className="TextBlock">
        <h2>{text.block7headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block7small}}></p>
      </div>

      <VideoLoop index="8" cover={loop['8'].cover} videoUrl={loop['8'].url} alt={loop['8'].alt}/>
      <div className="TextBlock">
        <h2>{text.block8headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block8small}}></p>
      </div>

      <VideoLoop index="9" cover={loop['9'].cover} videoUrl={loop['9'].url} alt={loop['9'].alt}/>
      <div className="TextBlock">
        <h2>{text.block9headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block9small}}></p>
      </div>

      <div className="TextBlock">
        <h2>{text.block10headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block10small}}></p>
      </div>

      <div className="TextBlock" dangerouslySetInnerHTML={{__html: text.block11small}}>
      </div>

      <div className="TextBlock">
        <h2>{text.block12headline}</h2>
        <p dangerouslySetInnerHTML={{__html: text.block12small}}></p>
      </div>

      <a className="imprintlink" href={`/?imprint=true&lang=${languageIsEnglish?'en':'de'}`}>Privacy Policy & Imprint</a>
    </div>
  )
}
export default App
