import React, { Component } from 'react'
import './VideoLoop.css'
import { Player, ControlBar } from 'video-react'
import 'video-react/dist/video-react.css'

class VideoLoop extends Component {
  constructor (props) {
    super(props)
    this.state = {
      videoURL: props.videoUrl,
      cover: props.cover,
      index: props.index,
      alt: props.alt
    }
  }
  render () {
    let content
    const { index, videoURL, cover, alt } = this.state
    const { inViewport } = this.props
    const video = document.getElementById(`backgroundVideo${index}`)
    if(video) video.play() // no viewport support, just start
    if (video && videoURL && inViewport) {
      console.log('play', index)
      video.play()
      this.props.tracker.trackEvent({ type: 'video', action: 'play', video: this.state })
    } else if (video && videoURL) {
      console.log('pause', index)
      video.pause()
      this.props.tracker.trackEvent({ type: 'video', action: 'pause', video: this.state })
    }
    if (video && video.play()) {
      setInterval(() => video.play(), 1000)
    }
    if (videoURL) {
      content = (
        <div className="videoContainer">
          <Player
            autoPlay={true}
            loop
            muted
            nocontrols
            controls={false}
            preload="auto"
            id={`backgroundVideo${index}`}
            className="backgroundVideo"
            playsInline={true}
            poster={cover}
            src={videoURL}
            onCanPlayThrough={() => {
            }}>
              <ControlBar disableCompletely={true} className="control-bar" />
          </Player>
        </div>
      )
    } else if (cover) {
      content = (
        <div className="videoContainer">
          <img src={cover} className="cover" alt={alt} />
        </div>
      )
    } else {
      content = (
        <div className="videoContainer">
          <p>no content</p>
        </div>
      )
    }
    return content
  }
}
export default VideoLoop
